const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://hasura.io',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://www.softwaretestingtrends.com/documents/terminal.svg',
    logoLink: 'https://ambreenkhan.dev/',
    title:
      "<a href='https://softwaretestingtrends.com/'>Tech Journal</a>",
    githubUrl: 'https://github.com/arkhangelsk/the-tech-journal',
    helpUrl: '',
    tweetText: '',
    social: `<li class="hiddenMobile">
		    <a href="https://github.com/arkhangelsk/" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='http://rmhsolutions.com/wp-content/uploads/2020/12/github-logo.png' alt={'Github'}/>
		      </div>
		    </a>
      </li>
      <li class="hiddenMobile">
		    <a href="https://www.linkedin.com/in/ambysan/" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='http://rmhsolutions.com/wp-content/uploads/2020/12/linkedin4.png' alt={'Linkedin'}/>
		      </div>
		    </a>
		  </li>
      <li class="hiddenMobile">
		    <a href="https://twitter.com/ambysan" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='http://rmhsolutions.com/wp-content/uploads/2020/12/twitter2.png' alt={'Twitter'}/>
		      </div>
		    </a>
		  </li>
			<li class="hiddenMobile">
		    <a href="https://www.facebook.com/softwaretestingtrend" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='http://rmhsolutions.com/wp-content/uploads/2020/12/facebook2.png' alt={'Facebook'}/>
		      </div>
		    </a>
      </li>
      <li class="hiddenMobile">
		    <a href="https://www.youtube.com/channel/UCi4ty05uf5Nqf0uZiW9x0kw" target="_blank" rel="noopener">
		      <div class="discordBtn">
		        <img src='http://rmhsolutions.com/wp-content/uploads/2020/12/youtube.png' alt={'YouTube'}/>
		      </div>
		    </a>
      </li>
      `,
      
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/home', // add trailing slash if enabled above
      '/javascript',
      'typescript',
      '/css',
      '/nodejs',
      '/python',
      '/java',
      '/datastructure',
      '/regular-expressions',
      '/github',
      '/apis',
      '/graphql',
      'web-architecture',
      '/useful-tools',
      '/Setting-up-you-mac-machine',
      '/vs-code',
      '/mobile-testing',
      '/automated-testing-wdio',
      '/writing-clean-code',
      '/mindmaps',
      '/ultralearning',
      '/codingchallenges',
      '/useful-code-snippets',
      '/career',
      '/stay-healthy',
      '/fun-time',
      '/courses',
      '/about'
    ],
    collapsedNav: [
      // '/java',
      // '/javascript', 
      // '/github',
      // '/apis',
    ],
    links: [{ text: 'Software Testing Trends', link: 'https://www.softwaretestingtrends.com' }],
    frontline: false,
    ignoreIndex: true,
    title:
      "👩‍🏫 <a href='https://thetechjournal.netlify.app/courses'>Courses </a><div class='greenCircle'></div>👩‍💻<a href='https://thetechjournal.netlify.app/about'>About</a>",
  },
  siteMetadata: {
    title: 'Gatsby Gitbook Boilerplate | Hasura',
    description: 'Documentation built with mdx. Powering hasura.io/learn ',
    ogImage: null,
    docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://www.softwaretestingtrends.com/documents/shapes-37716.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
